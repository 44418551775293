<template>
  <div
    v-if="item"
    class="orderCard"
    @click="clickEmit"
  >
    <div
      v-if="item.is_showed === false"
      class="flag"
    />
    <!-- <div
      v-if="item.avatar"
      class="avatar avatar-rounded"
      :style="{ backgroundImage: `url(${item.avatar})` }"
    /> -->
    <!-- <div v-else class="avatar avatar-rounded" style="background-image: url('/img/icons/default-user-circle.svg');"></div>     -->
    <div class="orderCardInfo">
      <div class="d-flex justify-content-between">
        <div class="fs15 text-success">
          <span class="font-weight-bolder">
            {{ item.full_name?item.full_name:item.chat_id }}</span>
        </div>

        <b-badge
          v-if="item.read_count"
          pill
          variant="danger"
        >
          {{ item.read_count }}
        </b-badge>
      </div>
      <div
        class="orderCardHead justify-content-between"
      >
        <div class="fs12 text-three-dots font-weight-bolder">
          {{ item.message }}
        </div>
        <div
          v-if="item.created_at"
          class="fs13 text-muted nowrap"
        >
          {{ formatTime(item.created_at) }}
        </div>
      </div>

      <!-- <div v-if="item.source" class="text-right">
        <b-badge pill :variant="item.source == 'telegram' ? 'info' : item.source == 'marketplace' ? 'warning' : 'primary'"
          class="mt-1 mb-1 ">
          {{ item.source }}
        </b-badge>
      </div> -->

      <div
        v-if="item.doing_user"
        class="text-right"
      >
        <b-badge
          pill
          class=""
        >
          {{ item.doing_user.name }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  computed: {
    isTest() {
      return this.$route.name == 'VacanciesShowTest'
    },
  },
  created() {
  },
  methods: {
    clickEmit() {
      if (!this.item.deleted_at) {
        this.$emit('click', true)
      }
    },
    formatTime(timestamp) {
      const date = new Date(timestamp)
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }
      return date.toLocaleString('en-GB', options)
    },
    ordersField(row) {
      if (row.products && row.products.length) {
        const newRow = row.products.map(el => ({
          quantity: el.quantity,
          name: (el.product && el.product.name_uz) ? el.product.name_uz : '',
          price: (el.product && el.product.price_dollar) ? (Number(el.product.price_dollar) * Number(this.dollarRate)) : '',
          id: el.category_id,
        }))
        return newRow
      }
      return []
    },
  },
}
</script>
<style lang="scss">
.orderCard {
  padding: 16px 20px;
  // @include clearfix;
  position: relative;
  cursor: pointer;

  &.active {
    background: #F1F7F9;
  }

  .avatar {
    width: 48px;
    height: 48px;
    float: left;
    margin-right: 12px;
  }

  .flag {
    width: 0;
    height: 0;
    border-right: 18px solid #40D382;
    border-bottom: 18px solid transparent;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
